<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div>
        <div class="f-xl f-bold p-3 border-bottom">
          รายละเอียดที่อยู่รับสินค้า
        </div>
        <EditAddressPanel
          :form="form"
          :v="$v"
          :disabledBtn="disabledBtn"
          @updateAddress="updateAddress"
        />
      </div>
    </div>
    <ModalRedeemAdress
      ref="modalRedeemAddress"
      :desciptionMsg="desciptionMsg"
      :btnMsg="btnMsg"
      :titleMsg="titleMsg"
      :hideClose="true"
      @submitModal="submitModal"
    />
    <ModalRedeemPoint
      ref="modalRedeemPoint"
      :detail="privilegeDetail"
      :totalPoint="form.total_point"
      :address="form"
      @redeemSuccess="redeemSuccess"
      @redeemError="redeemError"
      @hide="hide"
    />
    <ModalRedeemPointNewLayout
      v-if="$store.state.theme.line_layout == 'Compact'"
      ref="modalRedeemPoint"
      :detail="privilegeDetail"
      :totalPoint="form.total_point"
      :address="form"
      redeemQty="0"
      @redeemSuccess="redeemSuccess"
      @redeemError="redeemError"
    />
    <ModalError ref="modalError" :text="msg" />
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import ModalRedeemPointNewLayout from "@/components/reward/modal/ModalRedeemPointNewLayout";
import EditAddressPanel from "@/components/reward/detail/EditAddressPanel";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ModalError from "@/components/alert-modal/ModalError";
import ModalRedeemPoint from "@/components/reward/modal/ModalRedeemPoint";
import ModalRedeemAdress from "@/components/reward/modal/ModalRedeemAdress";

export default {
  components: {
    OtherLoading,
    ModalRedeemPointNewLayout,
    EditAddressPanel,

    ModalError,
    ModalRedeemPoint,
    ModalRedeemAdress
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "สิทธิพิเศษ",
          to: "/"
        }
      ],
      form: {
        picture: null,
        profile: null,
        email: "",
        first_name_th: "",
        last_name_th: "",
        first_name_en: "",
        last_name_en: "",
        nickname: "",
        telephone: "",
        birthday: "",
        gender: "",
        total_point: 0,
        home_address: "",
        town: "",
        alley: "",
        road: "",
        subdistrict: "",
        district: "",
        province: "",
        zip_code: "",
        birthday_day: "",
        birthday_month: "",
        birthday_year: "",
        is_consent: 0,
        note: ""
      },
      isLoading: true,
      disabledBtn: false,
      msg: "",
      id: this.$route.params.id,
      privilegeDetail: {},
      desciptionMsg: "",
      btnMsg: "",
      titleMsg: ""
    };
  },
  async created() {
    await this.getDataProfile();
    await this.getRedeemInfo();
  },
  validations() {
    return {
      form: {
        first_name_th: { required },
        last_name_th: { required },
        telephone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10)
        },
        home_address: { required },
        district: { required },
        subdistrict: { required },
        province: { required },
        zip_code: { required, minLength: minLength(5) }
      }
    };
  },
  methods: {
    async getDataProfile() {
      await this.$store.dispatch("getUserApi");
      this.form = JSON.parse(JSON.stringify(this.$store.state.shortProfile));
    },
    async getRedeemInfo() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/redeem/info/${this.id}/${this.$route.query.type}/0`
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.privilegeDetail = data.detail.redeem_item;
          }
        });
    },
    async updateAddress(form) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.isLoading = true;
      // let dynamic = [];

      // for (const fields of this.form.custom_field) {
      //   let target_id = 0;
      //   if (fields.field_choices.length > 0) {
      //     const findFieldId = fields.field_choices.find(
      //       choice => choice.name == fields.user_answer
      //     );
      //     if (findFieldId) {
      //       target_id = findFieldId.id;
      //     }
      //   }
      //   dynamic.push({
      //     id: fields.id,
      //     value: fields.user_answer,
      //     target_id: target_id
      //   });
      // }
      // let objTemp = [];

      // for (const x of dynamic) {
      //   var findField = this.form.custom_field.find(field => field.id == x.id);

      //   if (x.value && typeof x.value == "object") {
      //     if (x.value.length > 0) {
      //       for (const a of x.value) {
      //         // if (findField.field_choices.length > 0) {
      //         var choice = findField.field_choices.find(
      //           choice => choice.name == a.answer
      //         );
      //         // }
      //         let id = 0;
      //         if (choice) {
      //           id = choice.id;
      //         }
      //         objTemp.push({
      //           id: x.id,
      //           value: a.answer,
      //           target_id: id
      //         });
      //       }
      //     }
      //   } else {
      //     objTemp.push(x);
      //   }
      // }
      // let payload = {
      //   ...this.form,
      //   custom_field: objTemp.filter(el => el.value)
      // };

      // this.disabledBtn = true;
      // this.$axios
      //   .post(`${process.env.VUE_APP_API}/api/v1/user/EditUserProfile`, payload)
      //   .then(async data => {
      //     if (data.result == 1) {
      await this.$refs.modalRedeemPoint.submitRedeem();
      this.isLoading = false;
      // } else {
      //   this.disabledBtn = false;
      //   if (data.detail[0]) {
      //     this.successMessage = data.detail[0];
      //   } else {
      //     this.successMessage = data.message;
      //   }
      //   this.$refs.modalError.show();
      //   this.isLoading = false;
      // }
      // })
      // .catch(er => {
      //   this.msg = er.message;
      //   this.$refs.modalError.show();
      //   this.isLoading = false;
      // });
    },
    async redeemSuccess() {
      this.desciptionMsg =
        "ขอบคุณสำหรับการร่วมกิจกรรมและแลกของรางวัล กรุณารอรับสินค้าตามที่อยู่ที่ได้ลงทะเบียนในระบบ";
      this.btnMsg = "กลับหน้าหลัก";
      this.titleMsg = "แลกของรางวัลเสร็จสิ้น";
      await this.$store.dispatch("refreshUserProfile");
      this.$refs.modalRedeemAddress.show(true);
    },
    submitModal() {
      this.$router.push("/reward");
    },
    redeemError(data) {
      this.msg = data.detail;
      this.$refs.modalError.show(true);
    },
    hide() {
      this.isLoading = true;
      this.disabledBtn = false;
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
