<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      ref="modalRedeemPoint"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div class="m-3">
        <div class="text-center title mb-2">ยืนยันการแลกของรางวัล</div>
        <div class="text-center">
          <span class="f-bold"> {{ detail.name }}</span> <br />
          จำนวนสินค้า : <span class="f-bold"> {{ redeem_count }} ชิ้น</span>
        </div>
        <div class="mx-auto w-80 my-3">
          <div class="d-inline-flex w-100 align-items-center">
            <div class="f-bold">- คะแนนสะสมปัจจุบัน:</div>
            <div class="ml-auto">{{ totalPoint | numeral("0,0") }} คะแนน</div>
          </div>
          <div class="d-inline-flex w-100 align-items-center">
            <div class="f-bold">- คะแนนที่ต้องใช้:</div>
            <div class="ml-auto">
              {{ (detail.point_used * redeem_count) | numeral("0,0") }} คะแนน
            </div>
          </div>
          <div class="d-inline-flex w-100 align-items-center">
            <div class="f-bold">- คะแนนคงเหลือ:</div>
            <div class="ml-auto">
              {{
                (totalPoint - detail.point_used * redeem_count) | numeral("0,0")
              }}
              คะแนน
            </div>
          </div>
        </div>
        <hr />
        <div class="mt-2">
          หมายเหตุ:
          {{
            $store.state.theme.redeem_remark ||
            "หากทำการยืนยันแล้วจะไม่สามารถยกเลิกหรือแก้ไขการแลกของรางวัลได้"
          }}
        </div>
      </div>
      <div class="p-3 text-center">
        <b-row>
          <b-col>
            <b-button class="w-100" @click.prevent="close">ย้อนกลับ</b-button>
          </b-col>
          <b-col>
            <b-button
              @click.prevent="submitRedeem"
              class="w-100 btn-confirm"
              :disabled="isDisable"
              :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
              >ยืนยัน</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      required: true,
      type: Object
    },
    totalPoint: {
      required: true,
      type: Number
    },
    address: {
      required: true,
      type: Object
    },
    redeemQty: {
      required: false
    }
  },
  data() {
    return {
      modalShow: false,
      total: 0,
      body: {
        redeem_id: 0,
        product_id: 0,
        branch_id: 0,
        home_address: "",
        province: "",
        subdistrict: "",
        district: "",
        zip_code: "",
        note: "",
        redeem_type_id: 1,
        redeem_count: 1
      },
      redeem_count: this.$route.query.qty || 1,
      isDisable: false
    };
  },
  watch: {
    redeemQty(val) {
      this.redeem_count = val;
    }
  },
  methods: {
    show() {
      this.modalShow = true;
      this.total = this.totalPoint - this.detail.point_used;
    },
    close() {
      this.$emit("hide");
      this.modalShow = false;
    },
    async submitRedeem() {
      let detail = this.detail;
      if (this.detail.pick_up_type_id == 1) {
        this.$emit("confirm");
      } else {
        this.$emit("showLoading");
        this.body.redeem_id = detail.redeem_id;
        this.body.product_id = detail.product_id;
        this.body.home_address = this.address.home_address;
        this.body.subdistrict = this.address.subdistrict;
        this.body.district = this.address.district;
        this.body.province = this.address.province;
        this.body.zip_code = this.address.zip_code;
        this.body.note = this.address.note;
        this.body.redeem_type_id = this.$route.query.type;
        this.body.redeem_count = this.redeem_count;
        this.isDisable = true;
        await this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/Redeem/redeem_pick_at_home`,
            this.body
          )
          .then(data => {
            this.$emit("hideLoading");
            if (data.result == 1) {
              this.isDisable = false;
              this.$emit("redeemSuccess", data);
              this.close();
            } else {
              this.isDisable = false;
              this.msg = data.detail;
              this.$emit("redeemError", data);
              this.close();
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
}
.w-80 {
  width: 80%;
}
</style>
