<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modalError"
      hide-header
      hide-footer
      centered
      @hidden="hide"
    >
      <template v-if="$store.state.theme.line_layout == 'Compact'">
        <div class="text-center mb-4">
          <p class="f-xl mb-0 f-bold" v-if="text">{{ text }}</p>
        </div>
        <div class="text-center mb-4">
          <font-awesome-icon
            icon="times-circle"
            size="5x"
            style="color: var(--primary-color)"
          />
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <font-awesome-icon
            icon="times-circle"
            size="5x"
            class="mb-4"
            style="color: var(--primary-color)"
          />
        </div>
        <div class="text-center mb-4">
          <p class="f-xl mb-0 f-bold" v-if="text">{{ text }}</p>
        </div>
      </template>
      <div class="border-top p-2 text-center btn-confirm-popup">
        <div @click="hide">Close</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.modal-body {
  margin: auto;
  text-align: center;
}
</style>
