<template>
  <div class="content-edit-profile">
    <div>
      <InputText
        v-model="form.first_name_th"
        textFloat="ชื่อ"
        placeholder="ชื่อ"
        type="text"
        name="first_name_th"
        maxlength="60"
        :v="v.form.first_name_th"
        :isValidate="v.form.first_name_th.$error"
        class="f-regular mb-2"
      />
    </div>
    <div>
      <InputText
        v-model="form.last_name_th"
        textFloat="นามสกุล"
        placeholder="นามสกุล"
        type="text"
        name="last_name_th"
        maxlength="60"
        :v="v.form.last_name_th"
        :isValidate="v.form.last_name_th.$error"
        class="f-regular mb-2"
      />
    </div>
    <div>
      <InputText
        v-model="form.telephone"
        textFloat="เบอร์โทรศัพท์"
        placeholder="เบอร์โทรศัพท์"
        type="number"
        name="telephone"
        :oninput="`if( this.value.length > 10 )  this.value = this.value.slice(0,10)`"
        :v="v.form.telephone"
        :isValidate="v.form.telephone.$error"
        class="f-regular mb-2"
      />
    </div>
    <div>
      <InputText
        v-model="form.home_address"
        textFloat="ที่อยู่"
        placeholder="ที่อยู่"
        type="text"
        name="home_address"
        maxlength="60"
        :v="v.form.home_address"
        :isValidate="v.form.home_address.$error"
        class="f-regular mb-2"
      />
    </div>
    <div>
      <InputText
        v-model="form.subdistrict"
        textFloat="ตำบล/แขวง"
        placeholder="ตำบล/แขวง"
        type="text"
        name="subdistrict"
        :v="v.form.subdistrict"
        maxlength="40"
        :isValidate="v.form.subdistrict.$error"
        class="f-regular mb-2"
      />
    </div>
    <div>
      <InputText
        v-model="form.district"
        textFloat="อำเภอ/เขต"
        placeholder="อำเภอ/เขต"
        type="text"
        maxlength="40"
        name="district"
        :isValidate="v.form.district.$error"
        :v="v.form.district"
        class="f-regular mb-2"
      />
    </div>
    <div>
      <InputText
        v-model="form.province"
        textFloat="จังหวัด"
        placeholder="จังหวัด"
        type="text"
        maxlength="40"
        name="province"
        :isValidate="v.form.province.$error"
        :v="v.form.province"
        class="f-regular mb-2"
      />
    </div>
    <div>
      <InputText
        v-model="form.zip_code"
        textFloat="รหัสไปรษณีย์"
        placeholder="รหัสไปรษณีย์"
        type="number"
        inputmode="numeric"
        name="zip_code"
        oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
        :isValidate="v.form.zip_code.$error"
        :v="v.form.zip_code"
        class="f-regular mb-2"
      />
    </div>
    <div class="mb-2">
      <InputTextArea
        textFloat="หมายเหตุ"
        placeholder="หมายเหตุ"
        rows="5"
        v-model="form.note"
      />
    </div>
    <div class="my-3">
      <div>
        <small>
          * ที่อยู่ในการจัดส่งสินค้า ใช้ที่อยู่เดียวกับที่อยู่ส่วนบุคคล
          หากมีการแก้ไขจะใช้เป็นข้อมูลล่าสุด</small
        >
      </div>
    </div>
    <div class="register-bottom">
      <b-button
        class="w-100"
        variant="confirm-primary"
        @click="submit()"
        :disabled="disabledBtn"
        >ยืนยัน</b-button
      >
    </div>
  </div>
</template>

<script>
import InputTextArea from "@/components/input/InputTextArea";
import InputText from "@/components/input/InputText";
export default {
  components: {
    InputText,
    InputTextArea
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    disabledBtn: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    submit() {
      this.$emit("updateAddress", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-edit-profile {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 80px;
}
</style>
